<template>
	<div>
		<scNav navTag="scGpDail" :themeColor="themeColor"></scNav>
		<!-- 面包屑 -->
		<div style="max-width: 1440px;margin: 0 auto;">
			<crumbs :crumbs="['首页','专享产品服务']"></crumbs>
		</div>
		<div class="sc-width">
			<tap :taps="['专享产品服务']" :bgColor="themeColor"><i></i></tap>
			<newList @clkCup="clkCup" :themeColor="themeColor" :policy="list.data"></newList>
			<div class="block">
				<el-pagination
					background
					:page-size="parseInt(list.per_page)"
					@current-change="downPage"
					layout="total, prev, pager, next, jumper"
					:total="list.total">
				</el-pagination>
			</div>
		</div>
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import enterprise from "@/components/enterprise/enterprise"
	import scNav from '@/components/special/scNav'
	import newList from "@/components/new/newList"
	export default {
		data() {
			return {
				themeColor:"",
				list:{},
				page:1,
				type:0
			}
		},
		components:{
			enterprise,
			scNav,
			newList
		},
		created() {
			let type = this.$route.query.type
			this.type = type
			this.getList()
			if(type==1) {
				
				// 科技创新版
				this.themeColor = "#386eb8"
			}else if(type==2) {
				// 专精特新版
				this.themeColor = "#47a8c8"
			}else if(type==3) {
				// 交易版
				this.themeColor = "#d3a21e"
			}
			
		},
		methods:{
			clkEntAil(index) {
				this.$bus.$emit("clkEntAil",['首页','挂牌企业','挂牌企业详情'])
				this.$router.push({name:"scGpDails"})
			},
			// 获取列表
			getList() {
				let catid = 0
				let url = ""
				if(this.type==1) {
					catid = 119
					url = "Specialization/getList"
				}else if(this.type==2) {
					catid = 128
					url = "Technology/getList"
				}else if(this.type==3) {
					catid = 136
					url = "Trading/getList"
				}
				this.$axios.get(url,{
					params:{
						catid,
						limit:10,
						page:this.page
					}
				}).then(res=>{
					if(res.code) {
						this.list = res.data
					}
				})
			},
			// 点击进入详情
			clkCup(index,id) {
				let type = this.$route.query.type
				this.$router.push({name:"scGpDails",query:{id,type}})
			},
			// 切换页数
			downPage(e) {
				this.page = e
				this.getList()
			}
		}
	}
</script>

<style lang="less" scoped>
	@media (max-width:768px) {
		.not-tap {
			margin-bottom: 0;
		}
		.sc-width {
			padding-bottom: 0;
		}
	}
</style>